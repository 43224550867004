jQuery(document).ready(function($) {

    /* Fancy Box
    ------------------------------*/
    //jQuery(".gallery-icon a").fancybox().attr('data-fancybox', 'gallery-1');

    // $('.slick-gallery').each(function() {
    //     $(this).find('.gallery-item').each(function() {
    //       $(this).find(".gallery-icon a").attr('data-fancybox', 'images');
    //       var caption = $(this).find(".gallery-caption").html();
    //       $(this).find(".gallery-icon a").attr('data-caption', caption);
    //     });
    // });
    $('.gallery').each(function() {
      $(this).find('.gallery-item').each(function() {
        $(this).find(".gallery-icon a").attr('data-fancybox', 'images');
        var caption = $(this).find(".gallery-caption").html();
        $(this).find(".gallery-icon a").attr('data-caption', caption);
      });
    });

    $(".woocommerce-product-gallery__image a").fancybox().attr('data-fancybox', 'images');
    $(".gallery .gallery-item .gallery-icon.landscape a").fancybox().attr('data-fancybox', 'images');


    $().fancybox({
      selector : '[data-fancybox="images"]',
      loop     : true
    });

    // Home slider
    $(".slick-single").slick({
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000
    });

    // Testimonial slider
    $(".slick-testimonial").slick({
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      infinite: true,
      autoplaySpeed: 8000,
      speed: 700,
      fade: true,
    });

    //multi slider
    $(".slick-gallery").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
    });

    /* Smooth Scrolling
    ------------------------------*/
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
          &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });
      /* Back to Top
      ------------------------------*/
      $(window).scroll(function() {
          if ($(this).scrollTop() >= 50) {    // If page is scrolled more than 50px
              $('#backToTop').fadeIn();    // Fade in the arrow
          } else {
              $('#backToTop').fadeOut();   // Else fade out the arrow
          }
      });
      $('#backToTop').click(function() {      // When arrow is clicked
          $('body,html').animate({
              scrollTop : 0                  // Scroll to top of body
          }, 500);
      });
});